import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import BackButton from 'components/BackButton';
import Content from 'components/Content';
import Title from 'components/Title';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { updateBillingAgreement } from 'services/patient';
import { createBillingAgreement, executeBillingAgreement } from 'services/payments';
import { getTokenSelector, getUserSelector } from 'state/auth';
import { requestState } from 'state/request';

const Pay = () => {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const user = useRecoilValue(getUserSelector);
  const [request, setRequest] = useRecoilState(requestState);

  // RRD
  const navigate = useNavigate();

  const handleApprove = async (data) => {
    const response = await executeBillingAgreement(token, data.billingToken);
    // Guarda el billingToken en tu backend
    await updateBillingAgreement(token, user?.id, {
      paypal_billing_agreement_id: response.id,
    });

    const newRequestState = {
      hasPaymentMethod: true,
    };
    setRequest({
      ...request,
      ...newRequestState,
    });
    navigate('../pay');
  };

  return (
    <>
      <div className="col-span-1 md:col-start-2 px-4 w-20 mb-4">
        <BackButton onClick={() => navigate(-1)} />
      </div>
      <div className="sm:col-span-1 md:col-span-6 md:col-start-2 p-4">
        <div>
          <Title className="text-title">Método de pago</Title>
          <Content className="text-gray-500 my-4">
            En Mindove, tu bienestar es nuestra prioridad. <br /> <br />
            Para continuar, deberás añadir un método de pago. Tus datos bancarios estarán protegidos
            por <strong>PayPal</strong>, una plataforma confiable y segura.
            <br />
            <br />
            Usaremos esta tarjeta para gestionar de forma automática los pagos de tus sesiones, para
            que puedas enfocarte en lo más importante: tu progreso emocional y bienestar.
          </Content>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <PayPalScriptProvider
            options={{
              'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
              vault: true,
              intent: 'tokenize',
            }}
          >
            <PayPalButtons
              fundingSource="paypal"
              style={{ layout: 'vertical' }}
              createBillingAgreement={async () => {
                const response = await createBillingAgreement(token);
                return response.token_id;
              }}
              onApprove={handleApprove}
            />
          </PayPalScriptProvider>
        </div>
      </div>
    </>
  );
};

export default Pay;
