import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import BasicLayout from 'components/BasicLayout';
import Header from 'components/Header';
import React from 'react';
import toast from 'react-hot-toast';
import { useRecoilValue } from 'recoil';
import { updateBillingAgreement } from 'services/patient';
import { createBillingAgreement, executeBillingAgreement } from 'services/payments';
import { getTokenSelector, getUserSelector } from 'state/auth';

export default function ProfilePaymentMethods() {
  // Global state
  const token = useRecoilValue(getTokenSelector);
  const user = useRecoilValue(getUserSelector);

  // RRD
  // const navigate = useNavigate();

  const handleApprove = async (data) => {
    const response = await executeBillingAgreement(token, data.billingToken);
    // Save paypal billing agreement
    await updateBillingAgreement(token, user?.id, {
      paypal_billing_agreement_id: response.id,
    });

    toast.success('Se creo el Billing Agreement con la nueva tarjeta.');
  };

  return (
    <BasicLayout>
      <div className="grid grid-cols-1 gap-y-8 mt-4 md:mt-16">
        <Header
          title="Métodos de pago"
          description="Aquí podrá gestionar el método de pago que desea utilizar para la recurrencia de cobro de sesiones. (Mindove no guarda ninguna información confidencial)"
        ></Header>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <PayPalScriptProvider
            options={{
              'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
              vault: true,
              intent: 'tokenize',
            }}
          >
            <PayPalButtons
              fundingSource="paypal"
              style={{
                color: 'blue',
                layout: 'horizontal',
                shape: 'rect',
              }}
              createBillingAgreement={async () => {
                const response = await createBillingAgreement(token);
                return response.token_id;
              }}
              onApprove={handleApprove}
            />
          </PayPalScriptProvider>
        </div>
      </div>
    </BasicLayout>
  );
}
